/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

#reportContainer {
  overflow-y: auto;
}

#reportMap, #contextMap {
  height: 750px;
  border: 1px solid #e9e9e9;
  padding: 0;
}

.tblReport {
  font-family: 'basier_circleregular';
  border: none;

}
.tblReport th {
  background: #002664;
}

.tblReport tr {
  background: rgba(0,38,100,0.05);
  color: #4F4F4F;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba( 0, 57, 150, 0.15);
}

.tblReport thead tr{
  background: #47AAB1;
  color: #fff;
}

.tblReport thead th, .tblReport td, .tblReport th {
  border: 3px solid #fff;
}

.searchResult {
  margin-top: 18px;
  border-radius: 0;
  border: 2px solid #F0F0F0;
  /*padding: 15px;*/
}

.searchResult:hover {
  border: 2px solid #47AAB1;
}

.card-title {
  font-size: 20px;
  /* font-weight: 800; */
  color: #002664;
}

.card-text {
  margin: 0;
  color: #7b7b7b;
}

.card-footer {
/*  margin: 20px -20px -20px -20px;
  background-color: transparent;
  padding: 10px 0px 10px 19px;
  border-top: 2px solid rgba(0,0,0,.125);*/
  margin: 35px -20px -20px -20px;
  background-color: transparent;
  padding: 15px 0px 15px 20px;
  border-top: 2px solid #F0F0F0;
  border-radius: 0px !important;
  height: 38px;
}

.card-footer:hover {
  border-top: 2px solid #47AAB1;
}

.card-footer:hover a {
  /*color: #fff;*/
}

.reportCol {
  border-right: 2px solid #F0F0F0;
  margin: -15px 0px -15px -5px;
  height: 38px;
}

.reportCol:hover {
  background-color: #47AAB1;
  border-right: 2px solid #47AAB1;
}

.reportCol:hover a{
  color: #fff;
}

.reportLink {
  position: absolute;
  top: 5px;
  left: 5px;
}

/* report printing */
@media print {
  .table td {
    background-color: transparent !important;
    -webkit-print-color-adjust: exact;
  }

  .table th {
    background-color: #002664 !important;
    -webkit-print-color-adjust: exact;
  }

  .navbar {
    background-color: #002664 !important;
    color: #fff;
  }

  .no-print, .esri-feature-table__menu {
    display: none;
  }

  a:not(.btn) {
    text-decoration: none;
  }

  .pagebreak {
    -webkit-column-break-before: always;
       -moz-column-break-before: always;
            break-before: always;
  }

  .navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  body, page[size="A4"] {
    margin: 0;
    -webkit-box-shadow: 0;
            box-shadow: 0;
  }
}

/* Remove the group layer name from the legend */
.esri-legend__group-layer > .esri-legend__service-label {
  display: none;
}

.esri-legend__group-layer-child {
  margin-left: -15px;
}
/* END remove the group layer name from the legend */

/* .featureTable {
  height: 500px;
  width: 100%;
} */

.spanNumFeat {
  font-size: 14px;
}

.esri-feature-table {
  margin-left: -5px;
}

.esri-feature-table__loader-container {
  margin-left: 35px;
}

.esri-column__menu-container, .esri-feature-table__title {
  display: none !important;
}

.esri-feature-table__header {
   justify-content: flex-end;
 }

.esri-feature-table__menu {
  position: absolute;
}

.esri-field-column__header-content {
  background: #002664;
  color: #fff;
  padding: .75rem;
  border: 2px solid #fff;
}

vaadin-grid-cell-content {
  border: 2px solid #fff;
  background-color: rgba(0,38,100,.05);
  color: #4f4f4f;
  font-size: 14px;
  font-family: 'basier_circleregular';
  font-weight: 400;
}

.esri-grid .esri-column__sorter {
  color: #fff;
}

/* .esri-grid .esri-grid__content {
  font-size: 16px;
  font-family: 'basier_circleregular';
  font-weight: 400;
} */
.esri-feature-table__header {
  justify-content: flex-end;
}

.esri-field-column__header-content {
  font-size: 16px;
  font-family: 'basier_circleregular';
  font-weight: 700;
}
.esri-grid .esri-grid__grid {
  border: none;
  --lumo-contrast-20pct: #ffffff;
  --lumo-contrast-30pct: #ffffff;
  --lumo-contrast-10pct: #ffffff;
}

.esri-feature-table__header .esri-button-menu__button {
  margin-left: 10px;
}

.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.esri-feature-table__header .esri-button-menu__button:before {
  color: #a9a9a9;
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f013";
}
