/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@import 'bootstrap/dist/css/bootstrap.min.css';

@font-face {
    font-family: 'basier_circlebold';
    src: local('basier_circlebold'), url(./fonts/basiercircle-bold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'basier_circleregular';
    src: local('basier_circleregular'), url(./fonts/basiercircle-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'basier_circlemedium';
  src: local('basier_circlemedium'), url(./fonts/basiercircle-medium-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  overflow: hidden;

  /* from Figma */
  font-family: basier_circleregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;

}

page[size="A4"] {
  background: blue;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  -webkit-box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
          box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

#clcHeader {
  position: absolute;
  left: 180px;
  bottom: 5px;
}

#root, #exploreContainer {
  height: 100%;
}
a {
  color: #4e4a46;
  text-decoration: none;
}
a:hover,
a:focus {
  /* color: #c94e50; */
  text-decoration: none
}

h1, h2, h3, h4, h5 {
  color: #002664;
}

h1 {
  font-family: basier_circlebold;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-offset: 5px;
}

h2 {
  font-family: basier_circlebold;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.headerText {
  color: #fff;
  text-decoration: none;
}

h2.headerText {
  font-size: 20px;
  font-weight: 100;
  font-family: 'basier_circleregular';
}

p.headerText {
  margin-left: 20px;
  font-size: 14px;
  line-height: 14px;
  font-family: 'basier_circleregular';
  font-weight: 500;
}

@media screen and (max-width: 1400px) {
  h1.headerText {
    font-size: 24px;
  }

  h2.headerText {
    font-size: 16px;
  }
}

@media screen and (max-width: 800px) {
  h1.headerText {
    font-size: 20px;
    padding-top: 0;
  }

}

@media print {
  /* report print tweaks to the header */
  #logo, #clcHeader {
    display: block;
  }
  
  #activateTitle {
    margin-left: 480px;
  }
}

@media screen and (max-width: 1300px) {
  /* report print tweaks to the header */
  #logo, #clcHeader {
    display: none;
  }
  
}

.subheading, .form-check-label {
  color: #4F4F4F;
  font-weight: 400;
}

#logo {
  height: 45px;
}

main {
  height: 100%;
  padding: 0;
  background: #b4bad2;
  overflow: auto;
}

.base-container {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }
}

#activateTitleWrapper{
  pointer-events:none;
}

#activateTitleWrapper H1{
  position: absolute;
  left: 1rem;
  right: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}





@media screen and (min-width: 1301px) {
  #activateTitleWrapper{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    text-align:center;
  }
  
  #activateTitleWrapper H1{
    left: 0;
  }

}

#navbarRight {
  color: #fff;
  min-width: 225px; /* force center the activate text */
}

#navbarRight li {
  display:flex;
  align-items:center;
}

#navbarRight li a p{
  margin-bottom:0.5rem !important;
}

.headerButton {
  border-radius: 0;
  border: 2px solid white;
  margin: 5px 5px 5px 20px;
  background-color: #002664;
  height: 40px;
  width: 120px;
}

.headerButton:disabled {
  /*background-color: #727272;*/
  opacity: 0.5;
  border: 2px solid white;
}

.headerButton:hover:enabled {
  color: #fff;
  background-color: #47AAB1d9;
  border-color: white;
}

/* sidebar styles from https://bootstrapious.com/tutorial/sidebar/index2.html */
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

/* The height of the header must be subtracted from the height of the
   #findContainer, #sidebar and #content divs and their top margins */
#header {
  height: 75px;
  background-color: #002664;
  color: #fff;
}

#sidebar {
  display: block;
  width: 600px;
  position: fixed;
  top: 0;
  left: 0;  
  z-index: 999;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #e9e9e9;
  padding: 25px;
  margin-top: 75px;
  margin-bottom: 5px;
  height: calc(100% - 80px); /*subtract the top and bottom margins*/
  /* height: 100vh; */
}

#content {
  width: calc(100% - 600px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 75px);
  margin-top: 75px;
}

#findContainer, #loadingPanel, #mobileMessageContainer {
  height: calc(100% - 75px);
}

#mobileMessage {
  width: 300px;
  padding: 35px 25px 25px 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-duration: 0.5s;
  background-color: #fff;
}

#loadingPanel {
  width: 100%;
  position: absolute;
  background: #fff;
  color: #727272;
  z-index: 1000;
}

#findContainer, #mobileMessageContainer {
  overflow-y: auto;
  background-image: url(./images/landing.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  /* display: block; */
  /* width: 100%; */
  /* height: 100vh; */
  /* background-position: 0px 0px; */
  /* background-repeat: repeat-x; */
  /* position: relative; */
}


@-webkit-keyframes animatedBackground {
  from { background-position: 100% 0; }
  to { background-position: 0 0; }
}


@keyframes animatedBackground {
  from { background-position: 100% 0; }
  to { background-position: 0 0; }
}

#searchWrapper, #findPane {
  height: calc(100% - 80px);
}

/* #searchWrapper {
  overflow-y: auto;
  overflow-x: hidden;
} */



 @media only screen and (max-height: 900px)  {
   /* reposition things to make everything fit better on smaller screens*/
  /* #findContainer.loggedIn {
    align-items: start;
  } */


.form-check svg{
  position:relative;
  top:-1.5px;
}

  #btnExplore{
    bottom: 0;
  }

  .quickmapRow {
    margin-top: 0.5rem;
  }

  h2 {
    font-size: 18px !important;
    line-height: 18px !important;
  }

  p {
    margin-bottom: 0.5rem !important;
  }

  #findPane {
    margin-top: 5px !important;
  }

  .mt-4, .my-4 {
    margin-top: 1.25rem !important;
  }

  .mt-3, .my-3 {
    margin-top: 0.75rem !important; 
  }

  .pt-2, .py-2 {
    padding: 0px !important;
  }

  .navLink, .navLink.active, .navLink:focus, .navLink:hover {
    font-size: 28px !important;
    line-height: 28px !important;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.35rem !important;
  }

  #searchDiv, #FindForm .form-control {
    height: 35px !important;
  }

  .rbt-input-main {
    /* margin-top: -7px !important; */
    font-size: 14px !important;
  }

  .rbt-aux .rbt-close {
    margin-top: -12px !important;
  }

}






.left-15 {
  /* hack to move things left by 15 pixels to line up*/
  margin-left: -15px;
  margin-right: 15px;
}

#FindForm .form-control::-webkit-input-placeholder {
  color: #002664;
}
#FindForm .form-control::-moz-placeholder { 
  color: #002664;
}
#FindForm .form-control:-ms-input-placeholder { 
  color: #002664;
}
#FindForm .form-control:-moz-placeholder { 
  color: #002664;
}

#txtDistance {
  width: 60px;
  margin: 0px 10px 0px 10px;
  text-align: center;
  display: inline;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
   margin-right: -5px;
}

/* search for location placeholder text*/
input[type="text"].rbt-input-main::-webkit-input-placeholder {
  color: #002664;
  font-size: 16px;
}

#searchResults {
  min-height: 920px;
}

#btnRedoSearch {
  position: absolute;
  right: 15px;
  top: 225px;
}

#btnRedoSearch:hover, #btnRemoveAll:hover{
  color:#052158
}


#btnRedoSearch:hover svg path, #btnRemoveAll:hover svg path{
  fill:#052158
}

#btnRemoveAll {
  position: absolute;
  right: 25px;
  top: 385px;
}
.btn-outline {
  border: 1px solid #727272;
  border-radius: 0;
  padding: 10px 15px;
  width: 130px;
  color: #409fa7;
  background: #ffffff;
}

.btn-outline:hover {
  background: #e9e9e9;
  color: #727272 !important;
}

.btn-outline.active {
  background: #ffffff;
  /* color: white; */
}

.resultsHeading {
  color: #4F4F4F;
  font-size: 16px;
  font-family: 'basier_circleregular';
  margin-bottom: 0px;
}

.resultsSummary {
  color: #4F4F4F;
}

.resultsIcon {
  margin-top: -3px;
}

.btnRemoveFromResults {
  position: absolute;
  left: 5px;
  top: 0px;
}

#paginationRow {
  margin-left: -30px;
  margin-right: -80px;
}

.btnPagination {
  border: none;
  background: none;
  color: #C4C4C4;
}

.btnPagination:disabled {
  color: lightgrey !important;
  background: none !important;
}

.btnPagination:hover, .btnPagination:focus, .btnPagination:visited, .btnPagination.active, .btnExplore:hover, .btnExplore:focus, .btnExplore:visited {
   color: #47AAB1;;
   background: none;
   border-color: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #47AAB1d9;
}

#sidebar.active {
  margin-left: -600px;
}

#content.active {
  width: 100%;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -600px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
  }
  #content.active {
    width: calc(100% - 600px);
  }
  #sidebarCollapse span {
    display: none;
  }
}

#mainNav {
  margin-left: -15px;
  margin-top: -10px;
}

/* Search form tweaks */

/* typeahead search box*/

.rbt{
  height:100% !important;
}

.rbt-input-main {
  border: none;
  margin-top: 0px;
  height: 100%;
  padding: 0 10px 0 0;
  background: transparent;
  font-size: 16px;
  color: #002664;
}

.rbt-menu-custom-option {
  display: none;
}

.form-control:focus:not(.bordered) {
  background-color: transparent;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #002664;
}

.form-group {
  margin-bottom: 0;
}

#search {
  margin-top: 20px;
  height: calc(100% - 100px);
}

#FindForm {
  margin-top: 25px;
  padding-top:0px !important;
}

#FindForm .form-control, #searchDiv {
  border-radius: 0;
  border: 3px solid #052158;
  font-size: 14px;
  height: 44px;
  padding: 0 10px 0 10px;
  color: #002664;
}

.inputMinSize, .inputMaxSize {
  width: 150px;
}

.inputMaxSize {
  margin-left: -12px;
}

#btnRefineSearch {
  margin-top: -10px;
}

#btnRefineSearch:hover {
  background: #47AAB1;
  color: #fff;
}

.resultsButton {
  font-size: 14px;
  color: #4f4f4f;
}

.resultsButton:hover {
  /* override the standard button on-hover behaviour*/
  color: #409fa7;
}

.resultsText {
  font-size: 14px;
}

/* ArcGIS Search Widget */
#searchDiv {
  height: 36px !important;
}

.clcButton {
  background-color: #052158;
  color: #fff;
  border: none;
  border-radius: 0;
}

#FindForm:disabled {
  background-color: red;
  color: blue;
}

#btnSubmitFind:hover, #btnSubmitExplore:hover {
  color: #fff;
  background-color: #47AAB1;
  border-color: #47AAB1;
}

#imgExploreMap {
  margin-top: -3px;
}

.btn:disabled {
  background-color: #727272;
  color: #fff;
}

.turquoise, .Toastify__toast--info {
  background-color: #409fa7 !important;
  border: none;
}

.turquoiseText, .btn-turquoiseText, .btn-turquoiseText:hover {
  color: #409fa7;
}

label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

/* Override the "search within map extent" symbol*/
.fa-square, .fa-check-square, .fa-redo {
	color: #A9A9A9;
}

/* #btnExportReport {
  margin-top: -15px;
  margin-left: 2px;
} */

#btnCloseSource {
  position: absolute;
  right: -10px;
}

.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit; 
  z-index: 1055;
}

#btnCloseSidebar {
  position: absolute;
  top: 9px;
  right: 0;
}

.close {
  font-size: 18px;
}

.txtWarning {
  font-size: 13px;
  font-style: italic;
}

/* Layer list tweaks */
.esri-icon-non-visible::before {
  content: "\e610";
}
.esri-icon-visible::before {
  content: "\e611";
}

.esri-layer-list__item {
  border-bottom: none;
  margin: 5px 0;
}

.esri-layer-list__item-container {
  padding: 5px 0 5px 0;
}

.esri-layer-list__list {
  padding-right: 5px;
}

.navbar {
  background: #002664;
  color: #fff;
  height: 35px;
}

.navbar-brand {
  color: #fff;
}




#modalSaveToAGOL {
  width: 560px;
}

#modalSaveToAGOL .modal-content {
  padding: 30px;
}

#modalSaveToAGOL .modal-header {
  height: 50px;
  border-bottom: none;
}

#modalSaveToAGOL input[type=text] {
  padding-left: 15px;
  width: 323px;
}

#btnSaveToAgol {
  width: 323px;
}

#btnCloseSaveToAgol {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 20px;
}

#txtViewLayerInAGOL {
  font-size: 14px;
  position: absolute;
  width: 100%;
}

#txtViewLayerInAGOL a:hover{
  color: #409fa7;
}

p.message {
  font-size: 14px;
}

p.error {
  color: red;
}

.modal-body {
  font-size: 20px;
  font-weight: 500;
  color: #002664;
}

.modal-footer {
  border: none;
}

#searchMessages {
  list-style-type: none;
  font-size: 13px;
  color: #727272;
}

.progress-bar {
  background-color: #002664;
}



.navLink{
  /* Find */

left: 0%;
right: 79.57%;
top: 0%;
bottom: 0%;

font-family: basier_circlebold;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 32px;
/* identical to box height */
letter-spacing: 0.02em;

color: #C4C4C4;


}

.navLink:hover,
.navLink:focus ,
.navLink.active {
left: 0%;
right: 79.57%;
top: 0%;
bottom: 0%;

/* H1 */
font-family: basier_circlebold;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 32px;
/* identical to box height */
letter-spacing: 0.02em;
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;
text-decoration-thickness: 3px;
text-underline-offset: 5px;
color: #002664 !important;
text-underline-offset: 5px;

}

.type-icon {
  /* height: 70px; */
  /* height: 119px; */
  /*width: 185px;*/
  /* width: 100%; */
}

.type-icon-sm {
  height: 40px;
}

.icon-holder {
  height: 83px;
  display: block;
  width: 66px;
}

.btnExplore {
  border: none;
  background: none;
  color: #47AAB1;
  margin-top: 45px;
}

#btnExplore{
  position: absolute;
  right: 0;
}

#btnSubmitExplore, #btnCreateMap, #btnOpenReport {
  height: 45px;
  /* width: 350px; */
  width: 100%;
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  /* bottom: 110px; */
  color: #fff;
}

.btnCreateMap{
  margin-top:2.5rem !important;
  padding-top: 0px !important;
}


#searchWrapper_find .nav .nav-item .nav-link{
  padding-right:0px !important;
}

#searchWrapper_find .btnSubmitExplore{
  padding-right:1rem !important;
}

#searchWrapper_find #btnSubmitExplore{
  margin-right:unset !important;
  margin-left:0px !important;
}

#btnSubmitFind {
  height: 45px;
  width: 100%;
  text-align: center;
  position: relative;
  top: 10px;
}

.btn-quickmaps{
  max-width: 100%;
}
.btn-quickmaps:hover{
  background:none;
}
.icon-quickmaps:hover{
  color: #e9e9e9;
}
.icon-quickmaps:hover{
  color: yellow;
}

/* Print widget tweaks */
.esri-print__advanced-options-section, .esri-print__layout-tab-list {
  /*display: none;*/
}

.esri-print__form-section-container label {
  width: 100%;
}

/* loading indicator */
.loadingImg {
  margin: 0;
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-right: -50%;
  width: 30px;
}

#loadingImgExplore {
  margin: 0;
  position: absolute;
  left: 50%;
  margin-top: 50px;
  margin-right: -50%;
  width: 30px;
}

.row div[class^="col"]:first-child {
  padding-right: 15px;
  padding-left: 0;
}

.row div[class^="col"]:last-child {
  padding-right: 0;
  padding-left: 15px;
}

.row div[class^="col"]:not:first-child:not:last-child {
  padding-right: 15px;
  padding-left: 15px;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid #ECECEC;
}

#hiddenContainer {
  position: absolute;
  top: 9px;
  right: 0;
  display:none;
}

#collapseBtnContainer{
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

#showBtnContainer{
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

/* Legend tweaks */
.esri-legend {
  padding-top: 15px;
}

.esri-legend__service {
  padding: 0px 15px;
  border-bottom: none;
}

.esri-legend__group-layer {
  padding-top: 5px;
}

.esri-legend__group-layer-child {
  /*margin-left: -15px;*/
}

.esri-expand__content--expanded {
  z-index: 1000;
}

/* the minimap is an instance of the mainmap component on the Find page. it's
hidden because the body has no overflow, and is only used to pre-load the map
on the landing page, to save time on the Explore page */
#minimap {
  height: 100px; /* no really relavant as long as it's greater than zero */
}

/* printout map */
#printoutMap {
  height: 750px;
  border: 1px solid #e9e9e9;
  padding: 0;
}

#filterToolDiv {
  position: absolute;
  top: 50px;
  left: 50px;
  height: 220px;
  width: 400px;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

#labelFilterName {
  max-width: 100%;
  word-wrap: break-word;
}

.filterPlaceholder::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  /* styles copied from react-select equivalents*/
  color: hsl(0,0%,50%);
  font-size: 14px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

#filterForm input[type=number], #filterForm input[type=text], #filterForm input[type=date] {
  font-size: 14px;
  color: black;
}

.clcInput {
  height: 42px;
}

/* .filter.valid {
  background: rgba(64,159,167,0.15);
}

.filter.invalid {
  background: rgba(200,0,0,0.15);
} */

.filter {
  margin-top: 18px;
  border-radius: 0;
  border: 2px solid #C4C4C4;
  /*padding: 15px;*/
}

.btn-gray {
  color: #C4C4C4;
}

#overlayToolDiv {
  position: absolute;
  top: 250px;
  left: 400px;
  height: 465px;
  width: 400px;
  padding: 15px;
  overflow-y: auto;
}

/* force react-select dropdown text to look good without scrollbars
https://gitmemory.com/issue/JedWatson/react-select/2909/493127817
*/

.react-select__control, .clcInput {
  border-radius: 0 !important; 
  border: 3px solid #052158 !important;
}

.react-select__single-value, .clcInput {
  color: #002664 !important;
  font-size: 16px !important;
}

.customTool {
  background: #fff;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 2px 0px;
  border: none;
}

.esri-widget--button.active, .esri-widget--button.active:hover, .esri-widget--button.active:focus {
  cursor: default;
  background-color: #409fa7; /* #e9e9e9;*/
  color: white;
}
.esri-widget--button.active path, .esri-widget--button.active:hover path, .esri-widget--button.active:focus path {
  fill: #E4E4E4;
}

/* .esri-area-measurement-2d__settings, .esri-distance-measurement-2d__settings {
  display: none;
} */

.esri-distance-measurement-2d__measurement {
  background: #fff;
  margin: 0;
}

#measureDistance, #measureArea, #measureClear {
  border: none;
}

#measureArea {
  border-top: 1px solid rgba(110, 110, 110, 0.3);
  border-bottom: 1px solid rgba(110, 110, 110, 0.3);
}
/* End measurement Widget */

.hidden {
  display: none;
}

/* Screenshot https://developers.arcgis.com/javascript/latest/sample-code/sceneview-screenshot/live/ */
#screenshotDiv {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.js-screenshot-image {
  border: 10px solid white;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
  max-width: 100%;
  max-height: 100%;
}

#screenshotDiv > * {
  margin: 0.5em;
}

.screenshotCursor {
  cursor: crosshair;
}

.action-button {
  padding: 0.6em;
  border: 1px solid #0079c1;
  text-align: center;
  background-color: white;
  cursor: pointer;
}

.action-button:hover,
.action-button:focus {
  background: #0079c1;
  color: white;
}

#maskDiv {
  position: absolute;
  background: rgba(255, 51, 0, 0.1);
  border: 2px dashed rgb(255, 51, 0);
}

.modal-50w {
  width: 50%;
  max-width: none!important;
}

/* avoid ugliness with legend expanded on narrow screen */
.esri-view-width-xsmall .esri-expand--auto .esri-expand__mask--expanded {
  display: none;
}

/* avoid the blue outline on buttons. Accessbility issues? */
.btn.focus, .btn:focus {
  box-shadow: none;
}

/* try forcing the overlay button to sit inside the map to avoid infinite loop issue */
#overlayToolButton {
  position: absolute;
  bottom: -40px;
}

/* Explore Menu external link */
.exploreLayerLink {
  color: #47AAB1d9;
  font-size: 12px;
  margin-top: 5px;
  line-height: 14px !important;
}

.exploreLayerLink:visited {
  color: #47AAB1d9;
}

.exploreLayerLink:hover {
  color: #052158;
}

/* Sources modal */
#sourcesModal {
  max-width: 1000px;
  margin-top: 150px;
}

.card-header {
  cursor: pointer;
}


#btnExportReport:hover{
  color:#002664 !important;
  fill:#002664 !important;
  transition-duration:0.1s
}


#printoutContainer h2:first-of-type{
  margin-left:0.9rem !important;
}

#legend .esri-legend__layer, #legend .esri-legend__layer-body{
  margin-left:0px;
}

#printoutContainer .row div[class^="col"]:last-child{
  padding-left:0px !important;
}

#printoutContainer{
  margin-bottom:3.5rem !important;
}

#printoutContainer .row div[class^="col"]:last-child p{
  color:#4F4F4F !important;
  letter-spacing:0;
}



#printoutContainer .row div[class^="col"]:last-child p b{
  font-weight:bold;
  text-transform: uppercase;
}


#btnCreateMap:active, #btnSubmitExplore:active, #disclaimerbutton:active, #headerButton:active, #headerButton:visited, #btnOpenReport:active{
  background-color:#002664 !important;
}

#btnCreateMap:hover, #btnSubmitExplore:hover, #disclaimerbutton:hover, #headerButton:hover,  .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, #btnOpenReport:hover{
  background-color:#47AAB1 !important;
}

.nav .btn-primary:not(:disabled):not(.disabled):active{
  background-color:#002664 !important;
  border: 2px solid #ffffff
}



.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded{
  max-height: calc(100% - 120px) !important;
  top: unset !important;
  bottom: 30px !important;
}



/* VB CODE */


/* Changes to Explore/Find Form */

#searchWrapper_find {
  /* top: 10px; */
  /* transform: translate(-50%, 0); */
  padding: 30px;
  transition-duration: 0.5s;
  /* height: 690px; */
}

#searchWrapper  .nav-tabs, .nav-tabs .nav-link:hover{
  border:0px;
  border:none;
}



.nav-tabs{
  margin-bottom:0.75rem !important;
}

.nav-tabs .nav-link{
  border:0px !important;
}

.nav-tabs #find-explore-tabs-tab-profile, .nav-tabs #find-explore-tabs-tab-home{
 color:#C4C4C4;
 font-family:basier_circlebold;
 font-weight:bold;
 font-size:2rem;
 text-decoration:underline;
 text-decoration-thickness:3px;
 text-underline-offset: 5px;
 padding:0px;
}

.nav-tabs #find-explore-tabs-tab-profile:hover, .nav-tabs #find-explore-tabs-tab-home:hover{
color:#002664!important;
}

.nav-tabs #find-explore-tabs-tab-home{
 margin-left:1.125rem;
}

.nav-tabs .nav-link.active{
 border:0px;
 color:#002664!important;
}

/* #searchPane{
 margin-top:0 !important;
} */

#searchPane h2:first-of-type{
 color:#000000;
 font-size:1rem !important;
 font-family: basier_circleregular;
 font-weight:400 !important;
 letter-spacing:0 !important;
}

/* #searchDiv{
 margin-top:1.75rem !important;
} */


#searchWrapper #frmExploreMenu H5{
font-family: basier_circleregular;
font-weight:600;
color:#002664;
font-size:1em;
}

/* Safari 10.1+ */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    #searchWrapper #frmExploreMenu H5{
      letter-spacing:-0.75px;
      }

      /* #exploreTOC H2{
        font-size:18px !important;
        letter-spacing:-0.5px;
      } */
  }
}

#searchWrapper form hr{
position:relative;
left:-1rem;
border-top: 2px solid #F0F0F0;
}

#searchWrapper hr:first-of-type{
margin-top:0px;
}

#searchWrapper label{
color:#000000;
}

#searchWrapper #btnCreateMap, #searchWrapper #btnOpenReport{
  width:100%;
}

#searchWrapper_find #btnExplore:hover{
  background-color:transparent !important;
  color:#47AAB1 !important;
}

#searchWrapper_find #btnExplore{
font-weight:600 !important;
}

/* #frmExploreMenu{
  margin-bottom:5rem;
} */

#searchWrapper_find {
  background-color: #fff;
  width: 735px;
  padding: 35px 30px 30px 35px;
  /* height: 835px; */
  /* margin-bottom: 175px; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-duration: 0.5s;
}

#searchWrapper_welcome {
  background-color: #fff;
  width: 735px;
  padding: 35px 30px 30px 35px;
  /* height: 325px; */
  /* margin-top: -75px; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#searchWrapper_welcome p a:hover{
color:#002664 !important;
transition-duration:0.2s;
}

#searchWrapper_find #searchPane{
  margin-top:1.25rem !important;
}

#searchWrapper_find #searchPane #searchDiv{
margin-top:0px !important;
}

#searchWrapper_welcome h2, #mobileMessage h2{
  font-size: 2rem !important;
  line-height: 2rem !important;
  margin-bottom:1.5rem !important;
  max-width: 420px !important;
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

#mobileMessage h2{
  margin-bottom:1.875rem !important;
}

#mobileMessage h4{
font-size:1.375rem !important;
margin-bottom:1.875rem !important;
font-family: basier_circlebold;
}

@media screen and (max-width: 768px) {
  #activateTitleWrapper {
    display:none;
  }
}


#searchWrapper_welcome p:last-of-type{
  margin-bottom:1.25rem !important;
}

#searchWrapper_disclaimer {
  background-color: #fff;
  width: 735px;
  padding: 35px 30px 0px 35px;
  height: 600px;
  overflow-y: scroll;
  /* margin-top: -75px; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#searchWrapper_disclaimer H2, #searchWrapper_disclaimer H4, #searchWrapper_disclaimer div p, #searchWrapper_disclaimer li{
  text-align:left !important;
}

#searchWrapper_disclaimer div p a:hover{
  color:#002664 !important;
}

#searchWrapper_disclaimer H2, #terms-conditions{
  font-size:1.625rem !important;
  color:#002664 !important;
  font-family: 'basier_circlebold';
  font-weight:bold !important;
}

#searchWrapper_disclaimer H4{
  font-family: 'basier_circlebold';
  color:#4F4F4F;
  font-size: 1.25rem;
  font-weight:700;
  margin-top:30px;
}

#terms-conditions{
  margin-top:60px !important;
}

.disclaimerbutton{
  position:sticky;
  background-color:#ffffff;
  bottom:0px;
  left:0;
  width:100%;
  padding:30px 0px 30px 0px;
  text-align:right;
  border-top: #F0F0F0 2px solid;
}

.disclaimerbutton .headerButton{
  margin:0px !important;
}


.quickmapRow {
  margin-top: 1rem;
}


#searchWrapper a:focus, #searchWrapper a:focus svg, a:focus{
  color:#002664 !important;
  fill:#002664 !important;
}

@media screen and (min-height: 650px) {
  #findPane .public{
    padding-bottom:1.35rem !important;
    margin-top:2.5rem !important;
    padding-left:0px !important;
    position: absolute;
    bottom:0;
    margin-right: auto;
    left: 0;
    right: 0;
    width:100%;
    padding-left: 2.5rem !important;
  }

  #findPane .btnSubmitFind{
    padding-bottom:1rem !important;
  }

}


@media screen and (min-height: 900px) {

.btnCreateMap, .btnOpenReport{
  position:absolute;
  bottom:0;
  padding:0px !important;
  margin:0px !important;
  padding-right:3rem !important;
  padding-bottom:1.25rem !important;
  width:100%;
}

#findPane .btnSubmitFind{
  padding-bottom:1.25rem !important;
  margin-top:2.5rem !important;
  padding-left:0px !important;
  position: absolute;
  bottom:0.25px;
  margin-right: auto;
  left: 0;
  right: 0;
  width:100%;
  padding-left: 2.5rem !important;
}

}



#findPane .btnSubmitFind{
  margin-top:2.5rem !important;
  /* padding-left: 2.5rem !important; */
}

#findPane .btnSubmitFind .col-12{
  margin-right:10px !important;
}


.btnOpenReport{
  position:sticky;
  width: calc(600px - 50px) !important;
  padding:0px !important;
  left:0 !important;
  background-color:#ffffff;
  padding-top:1.25rem !important;
  padding-bottom:1.25rem !important;
  bottom:-40px !important;
  /* border-right: 1px solid #e9e9e9; */
  /* border-top: #F0F0F0 2px solid; */
}


/* Explore Form */

#frmExploreMenu hr:first-of-type{
  display:none;
}

#frmExploreMenu hr{
  width:548px !important;
}

#exploreTOC hr{
  width:100%;
  position: relative;
  border-top: 2px solid #F0F0F0;
  margin-right:18px !important;
  margin-bottom: 0px !important;
}

#frmExploreMenu .instructionalText{
  margin-top: 0.938rem !important;
  position:relative;
  left: -1rem !important;
  margin-bottom: 1.875rem !important;
}

.tab-content .rbt-aux .rbt-close{
  margin-top:4px !important;
}


/* Changes to Find Pane */

#findPane hr:first-of-type{
  display: none;
}

#findPane #FindForm{
  margin-top:3rem !important
}

#findPane #FindForm hr{
  width:100%;
  position: relative;
  border-top: 2px solid #F0F0F0;
  margin-right:18px !important;
  margin-left:1rem !important;
  margin-bottom: 0px !important;
  display:block !important;
}

#findPane .instructionalText{
  margin-top: 0.938rem !important;
  margin-bottom:0px !important;
}

#findPane .instructionalText strong em{
  font-style:normal !important;
}

#findPane .form-group:first-of-type .pl-1{
  padding-left: 0px !important;
}

#findPane .form-group{
  margin-top:1.25rem !important;
}


#findPane .form-group label, #findPane .form-group h2{
  font-family: basier_circleregular;
  font-weight: 600;
  color: #002664;
  font-size: 1em !important;
}

#findPane .form-group .col-sm-4:first-of-type{
  padding-left:0px;
}

#findPane .form-group .col-sm-4:last-of-type{
  padding-left:5px;
}

#findPane .form-group .col-sm-4 label{
  font-weight:500 !important;
}

#FindForm{
  position:relative;
}

#findPane #btnClear{
  position:absolute;
  top:-30px;
  right:0;
}

/* #findPane #btnClear:hover{
  color:#002664 !important;
} */



#findPane .btnSubmitFind #btnSubmitFind{
  top:0px;
}

#findPane .row div[class^="col"]:last-child{
  padding-left:0px !important;
  padding-right:15px !important;
}

#FindForm{
  padding-bottom:0px !important;
}


/* Changes to Search results */

#searchResults H2{
 margin-bottom:1.25rem !important; 
}

#searchWrapper #searchResults hr{
  left:unset !important;
  margin-bottom:1.125rem !important;
}

#searchWrapper #searchResults hr:first-of-type{
  margin-top:3rem !important;
}



.instructionalText em{
  font-style:normal !important;
  font-weight:bold;
}

.resultsHeading{
  font-weight:600 !important;
}

#searchWrapper #btnRedoSearch{
  padding:0px !important;
  top:254px !important;
  right:24px !important;
}

.selectwrapper{
  position:relative;
}

#searchWrapper #btnRemoveAll{
  padding:0px !important;
  top: -3px !important;
  right:24px !important;
}

#searchWrapper .form-row:first-of-type{
margin-top:0px !important;
}


#searchWrapper .btn-outline{
  border: 2px #F0F0F0 solid;
}

#searchWrapper .btn-outline:hover{
  border: 2px #47AAB1 solid;
  background-color:#fff !important;
  color:#47AAB1 !important;
}

#searchWrapper .btn-group .active{
  border: 2px #47AAB1 solid;
}

.form-row .btn-group{
  margin-left:-1px !important;
}

#searchWrapper .btn-group .mt-n1{
  margin-top:-0.15rem !important;
}

#searchWrapper .resultsSummary{
  margin-bottom:0px !important;
}

#searchWrapper .btn-group{
  margin-bottom: 1.375rem !improtant;
}

#searchWrapper .searchResult:first-of-type{
  margin-top:1.125rem !important
}

#searchWrapper .searchResult{
  margin-top:0.813rem !important
}

#searchWrapper .card-body {
  padding-right:0px !important;
}

#searchWrapper .card-title {
  font-size:1rem !important;
  margin-left:2.5rem !important;
  margin-top:0.77rem !important;
  margin-bottom:0.75rem !important;
}

#searchWrapper .card .btnRemoveFromResults{
  padding-left:10px !important;
  padding-right:10px !important;
  top:4px;
}

#searchWrapper .card .resultsIcon{
  margin-top:-5px !important;
}

#searchWrapper .btnZoomTo{
  background-color:transparent ;
}

#searchWrapper .singleReportButton, #searchWrapper .btnZoomTo{
  padding:0px !important;
  border-radius:0px !important;
  outline: 2px solid #F0F0F0;
  outline-offset-left: 1px;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  text-align: center;
  right:unset;
  top:unset;
}

.btnZoomTo img{
  margin-top:-1px;
}

#searchWrapper .singleReportButton:hover, #searchWrapper .btnZoomTo:hover{
  outline: 2px solid #47AAB1;
  z-index:100;
}

#searchWrapper .resultCol:hover{
  background-color:transparent !important;
} 

 #searchWrapper .resultCol button:hover{
  background-color:#ffffff !important;
  /* border: 2px solid #47AAB1; */
}

.searchResult:hover{
  border: 2px solid #F0F0F0 !important;
}

/* Changes to Data Sources */

@media screen and (max-width: 1180px) {
  #sourcesModal {
    padding:43px;
  }
}

.modal-body{
  word-break:break-word;
}

.modal-body .card-header{
  line-height:1.5rem;
}


.modal-content {
  border-radius: 0;
  border: 2px solid #E5E5E5;
  padding: 30px;
}


.modal-content H1{
text-decoration:none;
text-align:left;
}

.modal-content H1+p{
  margin-top:2.5rem;
  }

.modal-content{
  max-height:630px !important;
  overflow:scroll !important; 
}

.modal-body p{
  font-size:1rem;
  color:#000000;
  text-align:left;
}

.modal-body .col-3{
  z-index:100;
}

.modal-body .col-3 H4{
  font-family:basier_circlebold;
  font-size: 1.25rem;
  text-align:left;
}

.modal-body .col-9 H4{
  font-family:basier_circlebold;
  font-size: 1.625rem;
  text-align:left;
  margin-top:2.5rem;
  margin-bottom: 2.125rem !important;
}


/* Safari 10.1+ */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .modal-content H1{
      letter-spacing:-0.25px;
      }

      .modal-content li{
        letter-spacing:-0.5px;
        }

      .modal-content li{
        letter-spacing:-0.75px;
        }
  }
}


.card-header{
  font-size:1.25rem;
  text-align:left;
  font-family:basier_circlebold;
  font-weight:500;
  /* color:#000000 !important; */
  padding: 0.625rem 0rem;
  border:0px;
  border-top: 2px solid #F0F0F0;
  background-color:transparent;
}

.modal-body .card-body{
  padding:0px;
}

.card-body .description{
  margin-top:0.5rem;
}

.modal-body .card-body p{
  font-family:basier_circleregular;
  color:#002664 !important;
  font-size:1.25rem;
  font-weight:500;
  line-height:25px;
}

.card-body .col-5 H4{
  margin-top:0px !important;
  margin-bottom:0px !important;
  font-size:1rem !important;
  color:#002664 !important;
}

.card-body .col-7 H4, .card-body .col-12 H4{
  margin-top:1rem;
  margin-bottom:0px !important;
  font-size:1rem !important;
  color:#002664 !important;
}

.card-body .col-12{
  padding-left:0px !important;
}


.card-body .col-5 p, .modal-body .card-body .licenceinfo, .card-body .col-12 p{
  margin-top:0px !important;
  margin-body:0px !important;
  font-size:1rem !important;
  color:#4F4F4F !important;
  font-weight:500;
  line-height:20px;
}

.modal-body li{
  font-family:basier_circleregular;
  font-size: 1rem;
  text-align:left;
  list-style:none;
  text-decoration:underline;
  /* font-weight:600; */
  margin-top:1rem;
}

.modal-body li a{
  color:#002664;
}

.modal-body .col-3{
  position:fixed;
  border-right: 2px solid #F0F0F0;
  width:250px !important;
  height: calc(630px - 92px) !important;
}

.modal-body .col-9{
  padding-left:300px !important;
  max-width:100% !important;
  flex:unset !important; 
}

.modal-content #btnCloseSource{
  top:-12px !important;
  right:-8px !important;
}


@media screen and (max-width: 768px) {

  .modal-body .container .col-9{
    padding-left:200px !important;
  }


}


@media screen and (min-height: 1180px) and (max-width: 820px) {

  .modal-body .container .col-9{
    padding-left:250px !important;
  }


}



@media screen and (max-width: 920px) {

  .card-body .col-7, .card-body .col-5{
    max-width:100% !important;
    flex:unset;
  }

  .card-body .col-5{
    margin-top:1rem !important;
    padding-left:0px !important;
  }


}







.btnSubmitExplore{
  margin-top:1.5rem !important;
}

p a:hover, .accordion a:hover{
  color:#002664 !important;
  transition-duration:0.2s !important;
}

/* Report changes  */


#reportContainer .esri-legend__service{
  padding:0px !important;
}

#reportContainer .esri-legend__layer, #reportContainer .esri-legend__layer .esri-legend__layer-body{
  margin-left:0px !important;
}

#reportContainer .row .col-8 {
  padding-left:0px !important;
}

#reportContainer .row div[class^="col"]:last-child p b {
  font-weight: bold !important;
  text-transform: uppercase !important;
}

#reportContainer .row button{
  padding-left:0px !important;
}

#reportContainer .row button:hover{
  color:#002664 !important;
  fill:#002664 !important;
}



#reportContainer .row:last-of-type{
  margin-bottom:43px !important;
}




@media screen and (max-width: 768px) {
  .headerButton{
    width:100px;
  }

  #navbarRight{
    justify-content:flex-end !important;
  }

}


#reportContainer .row:nth-of-type(2) div:nth-of-type(2){
  margin-left: 50px;
  
}

@media screen and (max-width: 768px) {
  #reportContainer .row:nth-of-type(2) .col-lg-2{
    max-width:200px;
    flex: 0 0 35%;
  }

}



@media screen and (max-width: 1200px) {
  #reportContainer .row:nth-of-type(2) .col-lg-2{
    max-width:200px;
    flex: 0 0 25%;
  }

}

#btnExplore{
  display:none;
}


@media screen and (max-width: 1145px) {
  .esri-expand__content{
    box-shadow:none;
  }
}

.esri-scale-bar{
  z-index:-10;
}

/* Search results */
.btnZoomTo {
  position: absolute;
  right: 12px;
  top: 5px;
  padding: 0;
  border: none;
}

.singleReportButton {
  width: 107%;
  margin-left: -11px;
  text-align: left;
  font-size: 14px;
}

.resultCol {
  border-left: 2px solid #F0F0F0;
}

.resultCol:hover {
  background-color: #47AAB1;
}

.resultCol:hover a{
  color: #fff;
}


@media screen and (max-width: 768px){
  .navbar{
    justify-content:center !important;
  }

  #logo{
    display:block !important;
  }


  #mobileMessage{
    transition-duration:0s !important;
  }

  #sourcesModal, .modal-backdrop{
    display:none!important;
  }

}


@media screen and (max-width: 1024px) and (max-height: 600px){

  .navbar{
    justify-content:center !important;
  }

  #logo{
    display:block !important;
  }

  #findContainer, #activateTitleWrapper, #navbarRight, .wrapper{
    display:none !important;
  }

  #mobileMessageContainer{
    display:block !important;
    transition-duration:0s !important;
  }

 

  .col-lg-8. .row{
    flex: unset !important;
    max-width: unset !important;
  }


  #mobileMessageContainer #mobileMessage{
    transform: unset !important;
    width: calc(100% - 60px) !important;
  margin-left:auto !important;
  margin-right:auto !important;
  margin-top:50px !important;
  position: relative !important;
    top:unset !important;
    left: unset !important;
    transition-duration:0s !important;
  }

  #sourcesModal, .modal-backdrop{
    display:none!important;
  }

}

#loadingPanel {
  width:100% !important;
}

#loadingPanel row{
  width:100% !important;
  text-align:center !important;
}

#loadingPanel H4{
  text-align:center !important;
}


#FindForm .form-control{
  height:35px !important;
}

.rbt-input-main{
  padding-left:24px !important;
}

#searchDiv{
  padding-left:0px !important;
}

#searchDiv, .form-control, #btnCreateMap, #btnSubmitFind, #btnOpenReport, .card-title, .disclaimerbutton, #btnSubmitExplore, .card-header, .modal-body li, .btn-group{
  font-family: 'basier_circlemedium' !important;
}

.disclaimerbutton button {
  text-transform: uppercase !important;
}


.btn:disabled{
  background: transparent !important;
  color:#409fa7 !important;
}


.searchResult .row{
  margin-right:0px;
}


.resultCol{
  max-width:47px !important;
  /* width:36px !important; */
  position:relative;
  padding:0px;
  
}

.searchResult .resultCol:first-of-type{
  margin-left:auto;
}

#txtAddRemoveAltered em{
  font-size:12px !important;
  font-style: normal !important;
  color:#4F4F4F !important;
}

@media screen and (min-height: 900px) {
  .btnSubmitFind{
    padding-top: 1.25rem !important;
  }
}

#paginationRow{
  width:120%;
}

#paginationRow .col-md-4{
font-size:16px !important;
color:#4F4F4F !important;
}

#paginationRow button:hover{
  background-color:transparent !important;
}

#reportContainer .esri-legend__service:last-child{
  margin-left:0px !important;
}

#searchWrapper_disclaimer p a, #searchWrapper_find p a{
  color: #000 !important;
  text-decoration:underline;
}

#searchWrapper_disclaimer p a:hover, #searchWrapper_find p a:hover{
  color: #002664 !important;
}

input[type=text], input[type=button], select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  }

  /* quickmaps updates */

  .quickmapRow{
    margin-top:23px;
  }

  /* .quickmapRow:first-of-type{
    margin-top:36px;
  } */

  .quickmapRow .btn-quickmaps:hover{
    color:#47AAB1 !important;
  }

  .quickmapRow .btn-quickmaps:hover figcaption{
    color:#47AAB1 !important;
  }

  
  .quickmapRow .btn-quickmaps:hover svg path{
    fill:#47AAB1 !important;
  }

  .quickmapRow .btn-quickmaps{
    height:120px;
    position:relative;
    padding:0px;
    width:100%;
  }

  svg path{
    transition-duration:0.3s;
  }

  .quickmapRow .btn-quickmaps svg{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .quickmapRow .btn-quickmaps figcaption{
    color:#002664;
    font-family: 'basier_circlemedium' !important;
    transition-duration:0.3s;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    margin:auto;
    line-height:18px;
  }


.mapicon, .findicon{
  position: relative;
  margin-top: -3px;
}
 

.btnPagination:not(:disabled):not(.disabled):active, .btnPagination:focus{
  background-color:transparent !important;
}


#searchPane .rbt .dropdown-menu{
  width:549px !important;
  border-top-left-radius:0px !important;
  border-top-right-radius:0px !important;
  left:-3px !important;
}

.dropdown-item:active{
  background-color:#052158 !important;
}

#tooltip-right, #tooltip-left{
  line-height:1rem;
  padding:5px;
}


#paginationRow button:hover svg path{
  fill:#47AAB1 !important;
}


#paginationRow .btn:disabled:hover svg path{
  fill:#C4C4C4 !important;
}

button:focus-visited, button:focus{
  outline: none !important;
}

.close{
  opacity:1;
}


.close:not(:disabled):not(.disabled):hover{
  opacity:1;
}

.rbt-close::before{
  content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66667 0C2.98667 0 0 2.98667 0 6.66667C0 10.3467 2.98667 13.3333 6.66667 13.3333C10.3467 13.3333 13.3333 10.3467 13.3333 6.66667C13.3333 2.98667 10.3467 0 6.66667 0ZM6.66667 12C3.72667 12 1.33333 9.60667 1.33333 6.66667C1.33333 3.72667 3.72667 1.33333 6.66667 1.33333C9.60667 1.33333 12 3.72667 12 6.66667C12 9.60667 9.60667 12 6.66667 12Z' fill='%2347AAB1'/%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M7.21603 6.66672L9.5522 4.33055C9.70357 4.17918 9.70357 3.93298 9.5522 3.78161C9.40083 3.63024 9.15463 3.63024 9.00326 3.78161L6.66709 6.11778L4.33092 3.77979C4.17955 3.62842 3.93335 3.62842 3.78198 3.77979C3.63062 3.93115 3.63062 4.17735 3.78198 4.32872L6.11816 6.66489L3.78198 9.00289C3.63062 9.15425 3.63062 9.40045 3.78198 9.55182C3.85858 9.62842 3.95706 9.66489 4.05736 9.66489C4.15767 9.66489 4.25615 9.6266 4.33274 9.55182L6.66892 7.21565L9.00509 9.55182C9.08168 9.62842 9.18016 9.66489 9.28047 9.66489C9.38077 9.66489 9.47925 9.6266 9.55585 9.55182C9.70722 9.40045 9.70722 9.15425 9.55585 9.00289L7.21603 6.66672Z' fill='%2347AAB1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='6' height='6' fill='white' transform='translate(3.66669 3.66675)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  margin-right:2px;
  bottom: 1px;
  position:relative;
}

 .rbt-close::after{
  content: "CLEAR";
  color: #409fa7;
  font-size: 14px;
  font-family: 'basier_circleregular';
  bottom: 3px;
  position:relative;
  font-weight:200;
  opacity:1;
}

.rbt-close:hover .rbt-close::after{
  color: #052158;
} 


.rbt-aux{
  right:-30px;
  width:120px!important;
}



.rbt-close span:first-of-type{
  display:none;
}

#searchWrapper_find .rbt-close{
  position:relative;
  top:7px;
}


#searchWrapper_find #searchPane .rbt .dropdown-menu{
  width:670px !important;
}